import { checkAuth, signOut, clearAuthState } from './auth.js';

async function handleLogoClick(event) {
  event.preventDefault();
  const { session, subscription } = await checkAuth();
  if (session && subscription) {
    window.location.href = '/weekly-motion';
  } else {
    window.location.href = '/';
  }
}

export async function updateAuthDependentElements() {
  try {
    const { session, subscription } = await checkAuth();
    
    const elements = {
      getProButton: document.getElementById('getProButton'),
      feedback: document.getElementById('feedback'),
      loginButton: document.getElementById('loginButton'),
      userProfile: document.getElementById('userProfile'),
      userProfileImage: document.getElementById('userProfileImage')
    };

    const missingElements = Object.entries(elements)
      .filter(([key, value]) => !value)
      .map(([key]) => key);

    if (missingElements.length > 0) {
      console.warn(`Some header elements are missing: ${missingElements.join(', ')}`);
    }

    const toggleClass = (element, className, force) => {
      if (element && element.classList) {
        element.classList.toggle(className, force);
      }
    };

    if (session && subscription) {
      // Authenticated and subscribed users: show feedback link, hide getProButton
      toggleClass(elements.feedback, 'hidden', false);
      toggleClass(elements.getProButton, 'hidden', true);
      toggleClass(elements.loginButton, 'hidden', true);
      toggleClass(elements.userProfile, 'hidden', false);

      if (elements.userProfileImage) {
        const avatarUrl = session.user.user_metadata?.avatar_url || 'https://www.gravatar.com/avatar?d=mp&s=200';
        elements.userProfileImage.src = avatarUrl;
      }
    } else {
      // For non-subscribed or non-authenticated users: show getProButton, hide feedback link
      toggleClass(elements.feedback, 'hidden', true);
      toggleClass(elements.getProButton, 'hidden', false);
      toggleClass(elements.loginButton, 'hidden', false);
      toggleClass(elements.userProfile, 'hidden', true);
    }
  } catch (error) {
    console.error('Error updating auth elements:', error);
    // Fallback behavior in case of error
    toggleClass(document.getElementById('loginButton'), 'hidden', false);
    toggleClass(document.getElementById('userProfile'), 'hidden', true);
    toggleClass(document.getElementById('getProButton'), 'hidden', false);
    toggleClass(document.getElementById('feedback'), 'hidden', true);
  }
}

export async function initializeHeader() {
  await updateAuthDependentElements();
  setupHeaderListeners();

  const logoLink = document.getElementById('logoLink');
  if (logoLink) {
    logoLink.addEventListener('click', handleLogoClick);
  }
}

export function setupHeaderListeners() {
  const userProfileImage = document.getElementById('userProfileImage');
  const profileDropdown = document.getElementById('profileDropdown');
  const logoutButton = document.getElementById('logoutButton');

  if (!userProfileImage || !profileDropdown || !logoutButton) {
    console.warn('Some header listener elements are missing, setup may be incomplete');
  }

  if (userProfileImage && profileDropdown) {
    userProfileImage.addEventListener('click', () => {
      profileDropdown.classList.toggle('hidden');
    });

    document.addEventListener('click', (event) => {
      if (!userProfileImage.contains(event.target) && !profileDropdown.contains(event.target)) {
        profileDropdown.classList.add('hidden');
      }
    });
  }

  if (logoutButton) {
    logoutButton.addEventListener('click', async (e) => {
      e.preventDefault();
      try {
        await signOut();
        clearAuthState();
        window.location.href = '/';
      } catch (error) {
        console.error('Error during logout:', error);
      }
    });
  }
}
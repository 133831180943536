// footer.js

export function createFooter() {
    const footer = document.createElement('footer');
    footer.className = 'bg-stone-50 w-full mt-12';
    footer.innerHTML = `
    <div class="sm:px-6 lg:px-12 py-8 px-6">
        <div class="flex flex-col md:flex-row justify-between items-center py-6">
            <div class="flex flex-col items-center md:items-start mb-4 md:mb-0">
                <a href="/" id="logoLink">
                    <img src="https://api.overshooot.com/storage/v1/object/public/assets/logos/overshooot-logo-text-v1.1.png?t=2024-09-07T04%3A13%3A16.961Z" 
                    class="h-4 w-auto" alt="overshooot logo">
                </a>
                <p class="mt-2 text-sm text-stone-500">Save time finding that <span class="line-through text-stone-500">perfect</span> interaction.</p>
            </div>
            <div class="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6">
                <nav class="flex space-x-4">
                    <a href="/refund" class="text-sm text-stone-600 hover:text-stone-900 hover:underline">Refund policy</a>
                    <a href="/privacy" class="text-sm text-stone-600 hover:text-stone-900 hover:underline">Privacy</a>
                    <a href="/terms" class="text-sm text-stone-600 hover:text-stone-900 hover:underline">Terms of service</a>
                </nav>
                <div class="flex items-center space-x-3">
                    <a href="https://x.com/getovershooot" class="text-stone-400 hover:text-stone-500">
                        <svg class="h-4 w-4" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/company/overshooot" class="text-stone-400 hover:text-stone-500">
                        <svg class="h-4 w-4" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"></path>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
`;
    return footer;
}

export function setupFooter() {
    const footerContainer = document.getElementById('footerContainer');
    if (footerContainer && !footerContainer.hasChildNodes()) {
        footerContainer.appendChild(createFooter());
    }
}

export function wrapContent() {
    const body = document.body;
    const existingPageContainer = document.getElementById('page-container');

    // Check if the content is already wrapped to avoid duplication
    if (existingPageContainer) {
        return;
    }

    const pageContainer = document.createElement('div');
    pageContainer.id = 'page-container';
    pageContainer.className = 'flex flex-col min-h-screen';

    const contentWrap = document.createElement('div');
    contentWrap.id = 'content-wrap';
    contentWrap.className = 'flex-grow';

    // Move all body content into the content wrap, except the footerContainer
    while (body.firstChild) {
        if (body.firstChild.id !== 'footerContainer') {
            contentWrap.appendChild(body.firstChild);
        } else {
            pageContainer.appendChild(body.firstChild);
        }
    }

    pageContainer.insertBefore(contentWrap, pageContainer.firstChild);

    // Clear the body and add the wrapped content
    body.innerHTML = '';
    body.appendChild(pageContainer);
}